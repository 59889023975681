class Hero {
    constructor() {
        this.heroEl = document.getElementById('intro-canvas');

        if (this.heroEl) {
            this.init();
        }
    }

    init() {
        let svgViewBoxWidth = this.heroEl.viewBox.baseVal.width;
        let viewportWidth = document.documentElement.clientWidth;
        let scale = viewportWidth / svgViewBoxWidth < 1 ? 1 : viewportWidth / svgViewBoxWidth;
        this.angles = Array.from(this.heroEl.querySelectorAll('.angle'));
        
        let animationDelayIncrement = 400;
        let animationDelay = 500;

        this.angles.forEach((angle) => {
            let pathLength = angle.getTotalLength();
            angle.style.strokeDasharray = pathLength * scale;
            angle.style.strokeDashoffset = pathLength * scale;
            angle.style.animationDelay = `${animationDelay}ms`;
            animationDelay += animationDelayIncrement;
            
            if (viewportWidth < 940) {
                angle.style.strokeWidth = 4;
            }
        });
    }
}

export default new Hero();
