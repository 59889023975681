import picturefill from 'picturefill';
import { nav } from './app/nav';
import { globalToggles } from './app/global-toggles';
import { scrollToElement } from './app/scroll-to-element';
import Hero from './app/hero'
//import { pJax } from './app/pjax';

//let pjax = new pJax('a', '[data-pjax=main]');

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
        for (let registration of registrations) {
            registration.unregister();
        } 
    });
}