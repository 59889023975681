import $ from 'jQuery';

$("[data-scroll-to-element]").on("click", function (e) {
    // Prevent default behaviour
    e.preventDefault();
    // Get the target ele
    let anchorEl = $(this).attr("href");
    // Scroll away
    $("html, body").animate(
        { scrollTop: $(anchorEl).offset().top }
        , $(anchorEl).offset().top);
});