class Nav {
    constructor() {
        this.scrollTop = window.pageYOffset;

        this.headerEl = document.querySelector('.js-header');
        this.navEl = document.querySelector('.js-nav');

        this.addEventListeners();

        // Horrible hack to force redraws on ios
        if(/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
            this.navEl.classList.add('will-change');
        }

        // Call update when initialised
        this.update();
    }

    addEventListeners() {
        document.addEventListener('scroll', () => this.update(), false);
    }

    update() {
        let bottomOfHeaderOffsetTop = this.headerEl.offsetTop + this.headerEl.clientHeight;

        // If we've scrolled to the top of the nav's Y position
        // we need to add class sticky to the nav.
        if (window.pageYOffset >= this.navEl.offsetTop) {
            this.navEl.classList.add('fill');
        }

        // If we've scrolled back up and the bottom of the nav's Y
        // position is less than or equal to the Y position of the bottom
        // of the header, remove the fixed class.
        if(window.pageYOffset <= (bottomOfHeaderOffsetTop - this.navEl.clientHeight)) {
            this.navEl.classList.remove('fill');
        }

        // Resize the nav if the entire header is
        // scrolled off of the screen.
        if (bottomOfHeaderOffsetTop <= window.pageYOffset) {
            this.navEl.classList.add('transitioned');
        } 
        if (bottomOfHeaderOffsetTop > window.pageYOffset) {
            this.navEl.classList.remove('transitioned');
        }
    }
}
new Nav();
